<template>
  <div>
    <p v-if="driversList" class="pogination__text">
      {{ currentPage }}-{{ Math.ceil(rows / perPage) }} из {{ filterDriversList.length }}
    </p>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        v-if="driversList"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="filterDriversList"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template #cell(id)="data">
          <div>{{ data.index + 1 + (currentPage - 1) * perPage }}</div>
        </template>
        <template #cell(name)="data">
          <p>{{ data.item.name }}</p>
        </template>
        <template #cell(status)="data">
          <span
            class="table-btn__status"
            :class="{ 'table-btn__status-active': data.item.status !== 'free' }"
          >
            {{ data.item.status === "free" ? "Свободно" : "В пути" }}
          </span>
        </template>
        <template #cell(action)="data">
          <div>
            <a class="table-btn">
              <b-icon
                icon="pencil-fill"
                aria-hidden="true"
                class="mr-2"
                variant="warning"
                @click="$router.push(`/add/new-driver?id=${data.item.id}`)"
              />
            </a>
            <!--            <a @click="deleteHandler(data.item.id)">-->
            <!--              <b-icon icon="trash-fill" aria-hidden="true" variant="danger"></b-icon>-->
            <!--            </a>-->
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      swowItem: null,
      statusBtn: false,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "Номер",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "city_name",
          label: "Город",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "name",
          label: "ФИО водителя",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "position",
          label: "Должность",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "iin",
          label: "ИИН",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "documents.deadline",
          label: "Срок истечения",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "documents.doc_number",
          label: "№ Уд. личности",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "phone",
          label: "Телефон",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        // {
        //   key: 'date',
        //   label: 'Дата рождения',
        //   sortable: true,
        //   isRowHeader: true,
        //   class: "tb-all",
        // },
        {
          key: "action",
          label: "Действия",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
        {
          key: "table_number",
          label: "Табельный номер",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
      ],
    };
  },
  computed: {
    rows() {
      if (this.driversList) {
        this.$emit("spinner");
        return this.filterDriversList.length;
      }
      return 2;
    },
    ...mapState(["driversList", "textFilter"]),
    filterDriversList() {
      if (!this.driversList) {
        return [];
      }
      let driversData = this.driversList.data;

      driversData = driversData.filter(e => e.role == 'user');

      if (this.textFilter) {
        driversData = driversData.filter(
          (e) => e.name && e.name.toString().indexOf(this.textFilter || "") > -1 && e.role == 'user'
        );
      }
      return driversData;
    },
  },
  created() {
    this.$store.dispatch("getDriversList");
  },
  methods: {
    deleteHandler(user_id) {
      let ask = confirm("Are you sure you want to delete?");
      if (ask) {
        this.$api
          .post("/web/user-delete", {
            user_id: user_id,
            _method: "DELETE",
          })
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getDriversList");
          })
          .catch((e) => {
            this.$toast.error(e.response.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
}
.pogination,
.pogination__text {
  position: absolute;
  top: -83px;
  right: -3px;
}
.pogination__text {
  right: 128px;
  top: -70px;
}
.page-item:nth-child(2) {
  display: none;
}
.page-item {
  margin-right: 18px;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
</style>
